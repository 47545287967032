import React from 'react'

import styled from 'styled-components'
import { theme } from 'styled-tools'

import imgdestaques from '@monorepo-amais/commons/assets/img/imgdestaques.png'
import { navigate } from 'gatsby'
import imgixUrlOptimizerAuto from '../../utils/imgixUrlOptimizerAuto'

const CardUnidadeDestaqueDiv = styled.div`
	position: relative;
	margin-top: 35px;
	margin-bottom: 30px;
	border-radius: 15px;
	width: 100%;
	height: auto;
	cursor: pointer;

	@media (max-width: 767.98px) {
		object-fit: contain;
		margin-top: 30px;
		padding: 0;
	}
`

const CustomPositionDestaqueDiv = styled.div`
	position: relative;
`

const ImagemCardUnidadeDestaqueImg = styled.img`
	width: 100%;

	height: 263px;
	object-fit: cover;
	object-position: top;

	border-radius: 15px;

	@media (max-width: 767.98px) {
		position: relative;
		width: 100%;
		height: 192px;
		border-radius: 10px;
	}
`

const MaskCardUnidadeDestaqueDiv = styled.div`
	position: absolute;
	bottom: 0;
	width: 100%;
	/* height: 50%; */
	height: 139px;
	/* background: rgba(0, 0, 0, 0.2); */
	background: rgb(2, 0, 36);
	background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 90%, transparent 100%);
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
	margin-bottom: 5px;
	/* top: 50%; */

	@media (max-width: 767.98px) {
		width: 100%;
		height: 50%;
	}
`

const TextosCardUnidadeDestaqueDiv = styled.div`
	position: absolute;
	padding-left: 34px;
	bottom: -5px;
	margin-bottom: 10px;

	@media (max-width: 767.98px) {
		padding-left: 14px;
		display: flex;
		flex-wrap: wrap;
		bottom: 10px;
	}

	@media (min-width: 768px) and (max-width: 991.98px) {
		height: 50%;
	}
`

const NomeUnidadeDestaqueH1 = styled.h2`
	font-size: 20px;
	line-height: 1.3;
	letter-spacing: normal;
	text-align: left;
	color: ${theme('colors.white')};

	@media (max-width: 767.98px) {
		width: 100%;
		font-size: 16px;
		line-height: 1.19;
		margin-block-end: 0.2em;
		margin-block-start: 0;
	}
`

const EnderecoUnidadeDestaqueP = styled.p`
	font-size: 16px;
	line-height: 1.13;
	text-align: left;
	color: ${theme('colors.white')};

	@media (max-width: 767.98px) {
		margin-bottom: -30px;
		width: 77%;
		padding-right: 10px;
		margin-block-end: 0;
		margin-block-start: 0.2em;
	}

	@media (min-width: 768px) and (max-width: 991.98px) {
		margin-bottom: 5px;
	}
`

const KMUnidadeDestaqueDiv = styled.p`
	top: 0;
	min-width: 62px;
	height: 25px;
	border-radius: 4px;
	background-color: ${theme('colors.primary')};
	color: ${theme('colors.white')};
	text-align: center;

	@media (max-width: 767.98px) {
		margin-right: 10px;
		margin-bottom: 10px;
		position: relative;
		margin-block-end: 0;
		margin-block-start: 0.2em;
		padding: 0 1rem;
	}
`

const handleClick = id => () => navigate(`/unidades/${id}`)

/**
 * Componente que exibe a unidade destacada na tela de Unidades
 * @param {object} unit - objecto de unidade para exibir como destaque
 */
const UnidadeDestaque = ({ unit }) => {
	let unidDist = ''

	if (unit.distance) {
		unidDist = unit.distance < 1 ? `${(unit.distance * 1000).toFixed(0)} metros` : `${unit.distance.toFixed(0)} km`
	}

	console.log('fotoooo', imgixUrlOptimizerAuto(unit.thumbnail.imagem.imgix_url), imgdestaques)

	return (
		<CardUnidadeDestaqueDiv onClick={handleClick(unit.slug)}>
			<CustomPositionDestaqueDiv>
				<ImagemCardUnidadeDestaqueImg
					variant='top'
					src={imgixUrlOptimizerAuto(unit.thumbnail.imagem.imgix_url || imgdestaques)}
				/>
				<MaskCardUnidadeDestaqueDiv />
				<TextosCardUnidadeDestaqueDiv>
					<NomeUnidadeDestaqueH1>{unit.nome}</NomeUnidadeDestaqueH1>
					<EnderecoUnidadeDestaqueP>{unit.endereco}</EnderecoUnidadeDestaqueP>
					{unit.distance && <KMUnidadeDestaqueDiv>{unidDist}</KMUnidadeDestaqueDiv>}
				</TextosCardUnidadeDestaqueDiv>
			</CustomPositionDestaqueDiv>
		</CardUnidadeDestaqueDiv>
	)
}

export default UnidadeDestaque
