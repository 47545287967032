import React, { useState, useLayoutEffect, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { Grid, Pagination } from '../Blocks'
import { navigate } from 'gatsby'
import { PAGE_LIMIT_UNIT } from '../../utils/enums'
import THUMBNAIL_PADRAO from '../../images/thumbnail_padrao.png'
import { theme } from 'styled-tools'

import imgixUrlOptimizerAuto from '../../utils/imgixUrlOptimizerAuto'

const ContainerListaUnidade = styled.div`
	@media (min-width: 992px) {
		padding-bottom: 24px;
	}

	.row {
		margin: 0;
	}
`

const CardsUnidadesDiv = styled.div.attrs({
	className: 'card'
})`
	position: relative;
	color: ${theme('colors.black')};
	display: block;
	margin-bottom: 24px;
	border: none;
	cursor: pointer;

	@media (max-width: 767.98px) {
		width: 100%;
		// height: 210px;
		margin-bottom: 10px;
	}
`

const ImagemCardsUnidadesImg = styled.img`
	object-fit: cover;
	border-radius: 10px;
	width: 100%;

	max-height: 226px;

	@media (min-width: 361px) {
		max-height: 167px;
	}

	@media (min-width: 767.98px) {
		max-height: 294px;
	}

	@media (min-width: 992px) {
		max-height: 240px;
	}

	@media (min-width: 1024px) {
		max-height: 216px;
	}

	@media (max-width: 767.98px) {
		width: 100%;
		border-radius: 5px;
		padding-right: 0 !important;
	}
`

const TextosCardsUnidadesDiv = styled.div.attrs({
	className: 'card-body'
})`
	padding: 0;
	bottom: 0;
`

const NomeUnidadeCardsUnidades = styled.h2`
	font-weight: 500;
	padding: 0;
	margin: 0;
	margin-bottom: 5px;
	font-size: 20px;
	line-height: 1.3;
	text-align: left;
	color: ${theme('colors.black33')};

	@media (max-width: 767.98px) {
		top: 40px;
		font-size: 14px;
		line-height: 1.36;
		text-align: left;
		color: ${theme('colors.black33')};
		/* margin-bottom: 20px; */
	}
`

const EnderecoUnidadeCardsUnidades = styled.div.attrs({
	className: 'card-text'
})`
	width: 100%;
	font-size: 16px;
	line-height: 1.13;
	text-align: left;
	color: ${theme('colors.gray66')};

	@media (max-width: 991.98px) {
		width: 100%;
		font-size: 14px;
		line-height: 1.14;
		text-align: left;
		color: ${theme('colors.gray66')};
	}
`

const KMUnidadeCardsUnidades = styled.p`
	font-size: 16px;
	line-height: 1;
	text-align: left;
	color: ${theme('colors.primary')};
	margin-top: 0;

	@media (max-width: 767.98px) {
		padding-top: 10px;
	}
`

const CustomGrid = styled(Grid)`
	grid-template-columns: ${props =>
		props.noFilterMode ? 'repeat(auto-fill, minmax(327px, 1fr))' : 'repeat(auto-fill, minmax(312px, 1fr))'};

	@media (max-width: 767.98px) {
		grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
	}
`

/**
 * Componente que exibe as unidades em cards
 * @param {array} units - array com unidades para listagem
 */
const handleClick = slug => () => navigate(`/unidades/${slug}/`)
const CardsUnidades = ({ units, noFilterMode, unidadeDestaque, filter, hasRegions, hasFacilities }) => {
	const [items, setItems] = useState(units.slice(0, PAGE_LIMIT_UNIT))
	const [unidades, setUnidades] = useState([])

	let initialPage = 1
	if (typeof Storage !== 'undefined') {
		const sessionPage = sessionStorage.getItem('pageUnit')
		if (sessionPage) {
			initialPage = Number(sessionPage)
			sessionStorage.removeItem('pageUnit')
		}
	}
	const [page, setPage] = useState(initialPage)

	const pages = units.length / PAGE_LIMIT_UNIT
	/**
	 * Foi usado o Math.ceil para que quando o resultado de pages for um número quebrado/decimal ele arredonde para cima e anule o botão para a próxima página.
	 **/
	const totalPages = Math.ceil(pages)

	useLayoutEffect(() => {
		const offset = (page - 1) * PAGE_LIMIT_UNIT
		setItems(units.slice(offset, offset + PAGE_LIMIT_UNIT))
	}, [units, page])

	const handleChangePage = useCallback(
		page => {
			const offset = (page - 1) * PAGE_LIMIT_UNIT
			const newItems = units.slice(offset, offset + PAGE_LIMIT_UNIT)
			setPage(page)
			setItems(newItems)
		},
		[units]
	)
	useEffect(() => {
		setUnidades(
			items.map(item => {
				let unidDist = ''

				if (item.distance) {
					unidDist =
						item.distance < 1 ? `${(item.distance * 1000).toFixed(0)} metros` : `${item.distance.toFixed(0)} km`
				}

				return { ...item, distance: unidDist }
			})
		)
	}, [items])

	let uniDistDetalhe = ''
	if (unidadeDestaque && unidadeDestaque.distance) {
		uniDistDetalhe =
			unidadeDestaque.distance < 1
				? `${(unidadeDestaque.distance * 1000).toFixed(0)} metros`
				: `${unidadeDestaque.distance.toFixed(0)} km`
	}

	return (
		<ContainerListaUnidade>
			<CustomGrid noFilterMode={noFilterMode}>
				{!process.env.COSMIC_BUCKET === 'irn' && !hasRegions && !hasFacilities && filter.order === 'd' && (
					<CardsUnidadesDiv
						id={`card-unit-${unidadeDestaque.nome}`}
						key={`card-unit-${unidadeDestaque.nome}`}
						onClick={handleClick(unidadeDestaque.slug, page)}
					>
						{unidadeDestaque.thumbnail && unidadeDestaque.thumbnail.imagem.imgix_url && (
							<ImagemCardsUnidadesImg
								variant='top'
								src={imgixUrlOptimizerAuto(unidadeDestaque.thumbnail.imagem.imgix_url, 312, 204)}
							/>
						)}
						{(!unidadeDestaque.thumbnail || !unidadeDestaque.thumbnail.imagem.imgix_url) && (
							<ImagemCardsUnidadesImg variant='top' src={THUMBNAIL_PADRAO} />
						)}
						<TextosCardsUnidadesDiv>
							<NomeUnidadeCardsUnidades>{unidadeDestaque.nome}</NomeUnidadeCardsUnidades>
							<EnderecoUnidadeCardsUnidades>{unidadeDestaque.endereco}</EnderecoUnidadeCardsUnidades>
							{unidadeDestaque.distance && <KMUnidadeCardsUnidades>{uniDistDetalhe}</KMUnidadeCardsUnidades>}
						</TextosCardsUnidadesDiv>
					</CardsUnidadesDiv>
				)}
				{unidades && (
					<Pagination
						gridColumn='1 / -1'
						elementToRender={(unidade, page) => (
							<CardsUnidadesDiv
								id={`card-unit-${unidade.nome}`}
								key={`card-unit-${unidade.nome}`}
								onClick={handleClick(unidade.slug, page)}
							>
								{unidade.thumbnail && unidade.thumbnail.imagem.imgix_url && (
									<ImagemCardsUnidadesImg
										variant='top'
										src={imgixUrlOptimizerAuto(unidade.thumbnail.imagem.imgix_url, 312, 204)}
									/>
								)}
								{(!unidade.thumbnail || !unidade.thumbnail.imagem.imgix_url) && (
									<ImagemCardsUnidadesImg variant='top' src={THUMBNAIL_PADRAO} />
								)}
								<TextosCardsUnidadesDiv>
									<NomeUnidadeCardsUnidades>{unidade.nome}</NomeUnidadeCardsUnidades>
									<EnderecoUnidadeCardsUnidades>{unidade.endereco}</EnderecoUnidadeCardsUnidades>
									{unidade.distance && <KMUnidadeCardsUnidades>{unidade.distance}</KMUnidadeCardsUnidades>}
								</TextosCardsUnidadesDiv>
							</CardsUnidadesDiv>
						)}
						elementsPerPage={PAGE_LIMIT_UNIT}
						totalPages={totalPages}
						handleNextPage={handleChangePage}
						page={page}
						items={unidades}
					/>
				)}
			</CustomGrid>
		</ContainerListaUnidade>
	)
}
export default CardsUnidades
