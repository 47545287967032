/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
//import { Link } from 'gatsby'
import getBrand from '../../utils/getBrand'

const EmptyText = styled.p`
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${theme('colors.primary')};
	font-size: 20px;
`

const EmptyWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin: 20px 0 40px 0;
`

const Text = styled.span`
	font-size: 12px;
`

const Img = styled.img`
	height: 80px;
	width: 80px;
`
/**
 * Mostra a mensagem de resultado não encontrado
 * @param {*} props.condition condição em Booleano
 */
export default function EmptyResult({ condition, genre, searchType }) {
	return (
		<React.Fragment>
			{condition && (
				<EmptyWrapper>
					<Img alt='' src='https://m.media-amazon.com/images/I/51CHQnTEzOL._SS500_.jpg' />
					<EmptyText>
						{` Nenhum${genre === 'F' ? 'a' : ''} ${searchType ? searchType : 'resultado'} encontrado.`}
					</EmptyText>
					{/* <Text>
						Encontrou algo de errado? Nos envie uma mensagem <Link to='/fale-conosco/'>aqui</Link>
					</Text> */}
					<Text>
						Encontrou algo de errado? Nos envie uma mensagem{' '}
						<a href={getBrand().ouvidoria} target='_blank'>
							aqui
						</a>
					</Text>
				</EmptyWrapper>
			)}
		</React.Fragment>
	)
}
