/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import styled, { css } from 'styled-components'
import MediaQuery from '@monorepo-amais/commons/components/mediaQuery'
import { getStateNameByBucketName } from '../../utils/region'

import SEO from '../seo'

import { BreadcrumbAuto, MainContainer } from '../Blocks'

import './../reset.css'

import * as groupBy from 'lodash.groupby'

import { theme } from 'styled-tools'
import imgixUrlOptimizerAuto from '../../utils/imgixUrlOptimizerAuto'

const CarouselFrame = styled(Carousel)`
	margin-bottom: 30px;
	height: ${({ height }) => `${height}px`};
	.carousel {
		&.carousel-slider {
			padding-bottom: 35px;
			.slide {
				background: none;
			}
		}
		.control-dots {
			display: flex;
			align-items: center;
			justify-content: center;
			.dot {
				margin: 0 5px;
				transition: all 0.2s;
				box-shadow: none;
				background: ${props => props.theme.colors.grayf4};
				opacity: 1;
				width: 10px;
				height: 10px;
				position: relative;
				outline: none;
				&:after {
					content: '';
					display: block;
					position: absolute;
					top: -2.5px;
					left: -2.5px;
					opacity: 0;
					width: 15px;
					height: 15px;
					-webkit-border-radius: 50%;
					-moz-border-radius: 50%;
					border-radius: 50%;
					background: ${props => props.theme.colors.primary};
					-webkit-transition: all 0.1s ease-in-out;
					-moz-transition: all 0.1s ease-in-out;
					-ms-transition: all 0.1s ease-in-out;
					-o-transition: all 0.1s ease-in-out;
					transition: all 0.1s ease-in-out;
				}
				&.selected {
					background: ${props => props.theme.colors.primary};
					&:after {
						opacity: 1;
					}
				}
				&:hover {
					&:after {
						opacity: 1;
					}
				}
			}
		}
	}

	img {
		height: ${({ height }) => `${height}px`};
		object-fit: cover;
	}
`

const GeneralInfo = styled.div`
	margin-bottom: 30px;
	@media (min-width: 1024px) {
		display: grid;
		grid-template-columns: 0.5fr 1fr;
		grid-gap: 18px;
		margin-bottom: 30px;
		grid-template-rows: auto;
		grid-template-areas: 'office-hours map';
		.office-hours {
			grid-area: office-hours;
		}
		.map {
			grid-area: map;
			display: flex;
			flex-direction: column;
		}
	}
`

const Address = styled.p`
	padding: 0 50px 20px 50px;
	margin: 0 0 20px 0;
	color: ${props => props.theme.colors.gray66};
	font-size: 16px;
	line-height: 19px;
	text-align: center;
	border-bottom: 1px solid ${props => props.theme.colors.graye2};
	@media (min-width: 1024px) {
		padding: 10px 20px;
		text-align: left;
		color: ${props => props.theme.colors.gray33};
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		border-radius: 5px;
		background: ${props => props.theme.colors.grayf4};
		/* margin: 30px 15px 40px; */
	}
`

const MapContainer = styled.div`
	position: relative;
	overflow: hidden;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	flex-grow: 1;
`

const FadeEffect = styled.span`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 20px;
	background-image: linear-gradient(to bottom, rgba(255, 0, 0, 0), rgba(0, 0, 0, 0.1));
`

const title = css`
	color: ${props => props.theme.colors.black_fonts};
	position: relative;
	&:after {
		content: '';
		position: absolute;
		bottom: -10px;
		left: 0;
		width: 90px;
		height: 5px;
		background: ${props => props.theme.colors.primary};
	}
`

const MainTitle = styled.h1`
	${title}
	font-size: 18px;
	margin: 20px 15px 30px;
	@media (min-width: 768px) {
		font-size: 40px;
		margin: 30px 15px 40px;
	}
	@media (min-width: 1024px) {
		margin: 30px 0 40px;
	}
`

const SubTitle = styled.h2`
	${title}
	font-size: 18px;
	margin: 0 0 30px 0;
	@media (min-width: 768px) {
		font-size: 22px;
	}
`

const OfficeHoursTable = styled.div`
	display: table;
	width: 100%;
	-webkit-border-radius: 5px;
	-moz-border-radius: 5px;
	border-radius: 5px;
	border: 1px solid ${props => props.theme.colors.graye2};
	margin-bottom: 20px;
	.row {
		display: table-row;
		&:last-child {
			.cell {
				border-bottom: none;
			}
		}
	}
	.cell {
		display: table-cell;
		padding: 10px 20px;
		border-bottom: 1px solid ${theme('colors.white')};
		font-size: 16px;
		&.title {
			width: 40%;
			background: ${props => props.theme.colors.grayf4};
		}
	}
`

const Facilities = styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-bottom: 40px;
	.card {
		width: 195px;
		border: 1px solid ${props => props.theme.colors.graye2};
		-webkit-border-radius: 3px;
		-moz-border-radius: 3px;
		border-radius: 3px;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 20px 0;
		margin-right: 10px;
		/* &:last-child {
			margin: 0;
		} */
	}
	.icon {
		font-size: 45px;
		color: ${props => props.theme.colors.primary};
	}
	p {
		text-align: center;
		color: ${props => props.theme.colors.primary};
		font-size: 17px;
		margin: 0;
		padding: 20px 20px 0;
		line-height: 23px;
	}
	@media (max-width: 1024px) {
		justify-content: center;
		.card {
			margin-bottom: 20px;
		}
	}
`

const Img = styled.img`
	width: 51.5px;
	height: 52.5px;
`

const ServicoAtendido = styled.div`
	padding: 10px 15px;
	background-color: #fcfcfc;
	border-left: solid #009fde;
	width: 20%;
	display: inline-block;
	margin: 0px 2% 15px 0px;
	vertical-align: middle;
	height: ${props => `${props.examesHeight}px`};

	@media (max-width: 1023.98px) {
		width: 40%;
		margin: 0px 2% 15px;
	}

	@media (max-width: 767.98px) {
		width: 85%;
		margin: 0px 2% 15px;
	}
`

const ServicoAtendidoTextAlign = styled.div`
	display: flex;
	height: 100%;
	align-items: center;
`

const ObsHour = styled.p`
	font-weight: bold;
	color: ${props => props.theme.colors.gray66};
	font-size: 12px;
	margin: auto;
`

const parseHorarios = horariosAtendimento => {
	let { sabado, domingo, segunda_a_sexta } = horariosAtendimento

	sabado = sabado
		.map(horario => ({ ...horario, dia: 'sabado', titulo: horario.titulo.trim() }))
		.filter(horario => horario.titulo !== '')
	domingo = domingo
		.map(horario => ({ ...horario, dia: 'domingo', titulo: horario.titulo.trim() }))
		.filter(horario => horario.titulo !== '')
	segunda_a_sexta = segunda_a_sexta
		.map(horario => ({ ...horario, dia: 'segunda_a_sexta', titulo: horario.titulo.trim() }))
		.filter(horario => horario.titulo !== '')

	const all = [...segunda_a_sexta, ...sabado, ...domingo]

	const grouped = groupBy(all, 'titulo')

	return grouped
}

const parseHorarioDia = string => {
	if (string === 'segunda_a_sexta') return 'Segunda a Sexta'
	else if (string === 'sabado') return 'Sábado'
	else return string.charAt(0).toUpperCase() + string.slice(1)
}

/**
 * Componente utilizado no template das unidades.
 * Código do template em templates/unidades.js
 *
 * @todo Verificar como podemos mostrar o estado (sp, pe, ba) nessa página.
 *
 * @param props Props recebidas do template
 */
const UnidadeDetalhe = ({
	nome,
	endereco,
	bairro,
	cidade,
	estado,
	facilidades,
	horariosAtendimento,
	fotos,
	exames,
	Layout
}) => {
	const horariosParsed = parseHorarios(horariosAtendimento)
	const facilities = facilidades.filter(f => !!f.facilidade)
	const height = typeof window !== 'undefined' ? window.innerHeight * 0.6 : 455
	const [examesSize, setExamesSize] = React.useState(-1)
	const [examesHeight, setExamesHeight] = React.useState(20)

	const banners = fotos.map(({ imagem }) => (
		// eslint-disable-next-line react/jsx-key
		<div key={`carousel-unit-detail-${imagem.imgix_url}`}>
			{/* <img src={imgixUrlOptimizerAuto(imagem.imgix_url, undefined, 455)} alt={nome} /> */}
			<img src={imagem.imgix_url} alt={nome} />
		</div>
	))

	const examesArray = exames.length > 0 ? exames.split(';') : []

	//console.log(facilities)

	let examesElements = []

	if (typeof window !== 'undefined') {
		document.getElementsByName('ExameAtendido')
	}

	React.useEffect(() => {
		let newExamHeight = examesHeight
		examesElements.forEach(element => {
			newExamHeight = element.offsetHeight > newExamHeight ? element.offsetHeight : newExamHeight
		})
		setExamesHeight(newExamHeight)
	}, [examesSize])

	if (examesSize !== examesElements.length) setExamesSize(examesElements.length)

	const nameBrandUnity = getStateNameByBucketName()
	const seoTitle = `Unidade ${nome} > Laboratórios`
	const seoDescription =
		`A unidade ${nome} ${nameBrandUnity} está situada em ${endereco} - ${bairro}. ` +
		'Confira o horário de atendimento para realização de exames.'
	return (
		<Layout>
			<SEO title={seoTitle} description={seoDescription} />
			<>
				<MainContainer>
					<MediaQuery minDeviceWidth={1024}>
						<BreadcrumbAuto title={nome} />
					</MediaQuery>

					<MainTitle> {nome} </MainTitle>

					<CarouselFrame
						height={height}
						autoPlay
						emulateTouch
						infiniteLoop={banners.length > 1}
						showArrows={false}
						showStatus={false}
						showThumbs={false}
						useKeyboardArrows
					>
						{banners}
					</CarouselFrame>

					<MediaQuery maxDeviceWidth={1024}>
						<Address>
							{endereco} | {bairro} | {cidade} - {estado}
						</Address>
					</MediaQuery>

					<GeneralInfo>
						<div className='office-hours'>
							{Object.keys(horariosParsed).map(tipoHorario => (
								<>
									<SubTitle>{tipoHorario}</SubTitle>
									<OfficeHoursTable>
										{horariosParsed[tipoHorario].map(horarios => (
											<div key={`hour-${horarios.dia}`} className='row'>
												<div className='cell title'> {parseHorarioDia(horarios.dia)}</div>
												<div className='cell'>
													das {horarios.horario_inicial} às {horarios.horario_final}
													<ObsHour>{horarios.observacao}</ObsHour>
												</div>
											</div>
										))}
									</OfficeHoursTable>
								</>
							))}
						</div>
						<MediaQuery minDeviceWidth={1224}>
							<div className='map'>
								<SubTitle> Endereço </SubTitle>
								<Address id='span-unit-address'>
									{endereco} | {bairro} | {cidade} - {estado}
								</Address>
								<MapContainer>
									{/* iframe feito em https://www.mapsdirections.info/en/custom-google-maps/ */}
									<iframe
										title='map'
										src={
											'https://maps.google.com/maps?' +
											`hl=pt&q=${encodeURIComponent(
												nome + ' ' + endereco + ' ' + bairro + ' ' + cidade
											)}+(${encodeURIComponent(nome)}` +
											')&ie=UTF8&t=&iwloc=A&output=embed&z=14'
										}
										frameBorder='0'
										style={{ border: 0, height: '100%', width: '100%' }}
										allowFullScreen
									/>
									<FadeEffect />
								</MapContainer>
							</div>
						</MediaQuery>
					</GeneralInfo>

					{facilities.length > 0 && <MainTitle> Facilidades </MainTitle>}
					<Facilities>
						{facilities.map(facilidade =>
							facilidade.icone.imgix_url ? (
								<div key={`facility-item-list-${facilidade.title}`} className='card'>
									<Img src={`${imgixUrlOptimizerAuto(facilidade.icone.imgix_url)}`} />
									<p> {facilidade.facilidade} </p>
								</div>
							) : (
								''
							)
						)}
					</Facilities>

					{examesArray.length > 0 && <MainTitle> Serviços atendidos nesta unidade </MainTitle>}
					{examesArray.length > 0 && (
						<div>
							<div>
								{examesArray.map(exame => (
									<ServicoAtendido key={exame} examesHeight={examesHeight}>
										<ServicoAtendidoTextAlign>
											<span name='ExameAtendido'>{exame}</span>
										</ServicoAtendidoTextAlign>
									</ServicoAtendido>
								))}
							</div>
						</div>
					)}
				</MainContainer>
			</>
		</Layout>
	)
}

export default UnidadeDetalhe
