/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo, useCallback } from 'react'

import styled from 'styled-components'

import UnidadeDestaque from './UnidadeDestaque'
import CardsUnidades from '@monorepo-amais/commons/components/unidades/CardsUnidades'
import { BreadcrumbAuto, MainContainer, Content, Div, FlexDiv } from '@monorepo-amais/commons/components/Blocks'
import { Search, Select, CheckBox, CheckboxContainer, CheckboxSpan } from '@monorepo-amais/commons/components/Inputs'
import { IoIosArrowDown, IoIosPin } from 'react-icons/io'
import { MainTitle, SubTitle } from '@monorepo-amais/commons/components/Typography'

import { calcRadius } from '@monorepo-amais/commons/utils/calcs'
import EmptyResult from '@monorepo-amais/commons/components/EmptyResult'
import themeObject from '../../theme'

import { theme } from 'styled-tools'

const CustomContent = styled(Content)`
	display: ${props => (props.noFilterMode ? 'block' : 'grid')};
	grid-template-areas: 'l l l r r r r r r r r r';

	@media (max-width: 991.98px) {
		grid-template-areas: 'r r r r r r r r r r r r';
		aside {
			display: none;
		}
	}
`

const CheckboxFilterH4 = styled.h4`
	color: ${theme('colors.gray46')};
	font-size: 16px;
`

const SidebarAside = styled.aside`
	padding: 20px 10px;
	border: 1px solid ${theme('colors.graye2')};
	border-radius: 5px;
`

const PinIconArrow = styled(IoIosPin)`
	color: ${theme('colors.primary')};
	width: 20px;
	height: 20px;
	padding-right: 5px;
`

const FiltrosMobileDiv = styled.div`
	@media (max-width: 991.98px) {
		display: flex;
		justify-content: space-between;
	}

	@media (min-width: 992px) {
		display: none;
	}
`

const NavbarCustom = styled.div`
	background: #edeff4;
	height: 50px;
	align-items: center;
	@media (max-width: 991.98px) {
		display: none;
	}

	@media (min-width: 768px) and (max-width: 991.98px) {
		> .navbar-toggler {
			padding-left: 45px;
		}
	}
`

const NavCount = styled.p`
	@media (min-width: 992px) {
		font-size: 14px;
		color: ${theme('colors.gray46')} !important;
		font-weight: 600;
		margin: 0;
	}
`

const NavTexto = styled.p`
	@media (min-width: 922px) {
		font-size: 14px;
		color: ${theme('colors.gray46')} !important;
		padding-right: 5px;
	}
`

const CustomIconArrow = styled(IoIosArrowDown)`
	padding: 0 0 0 10px;
	color: ${theme('colors.primary')};
	font-size: 20px;
	left: -15px;
	position: relative;
`

const NavSelect = styled.select`
	font-size: 15px;
	color: ${theme('colors.primary')} !important;
	font-weight: 600;
	background-color: transparent;
	border: 0;
	-webkit-appearance: none !important;
	-moz-appearance: none;
	position: relative;
	z-index: 1;
	width: 90px;
	left: 9px;
	cursor: pointer;
	outline: none;

	&:focus {
		outline: none;
	}
`

const NavOption = styled.option`
	@media (min-width: 922px) {
		font-size: 15px;
		color: ${theme('colors.primary')} !important;
	}
`

const DivForSearchCepMobile = styled.div`
	display: none;
	height: 60px;

	& > div {
		position: absolute;
		left: 0;
		right: 0;
	}

	span {
		border: none;
		background: ${theme('colors.grayf1')};
		border-radius: unset;
		padding: 10px;
		border-radius: 5px;
	}

	@media (max-width: 992px) {
		display: block;
	}
`

const SpanBelowInput = styled.span`
	font-size: 12px;
	color: ${theme('colors.gray66')};
	font-style: italic;

	@media (max-width: 768px) {
		margin-top: 2rem;
		width: 100%;
		display: block;
	}
`

const SpanBelowInputMobile = styled(SpanBelowInput)`
	display: none;

	@media (max-width: 992px) {
		display: block;
	}
`

/**
 * Componente que desenha a página de unidades em detalhes
 * @param {array} units - unidades recuperadas da query parsed no arquivo em pages
 * @param {array} facilities - facilidades recuperadas da query parsed no arquivo em pages
 * @param {array} regions - regiões recuperadas da query parsed no arquivo em pages
 * @param {object} latlong - objeto com infos de GPS caso esteja permitido
 */
const Unidades = ({ units, facilities, regions, setUnits, latlong }) => {
	/**
	 * Status
	 * IDLE: quando está ocioso
	 * GPS_ENABLED: permitiu a localização por GPS
	 * FILTERED: quando pesquisou um endereço/cep ou aplicou um filtro
	 * LOADING: esperando resposta da api do GMAPS
	 */
	const [pageStatus, setPageStatus] = useState(latlong ? 'GPS_ENABLED' : 'IDLE')

	const [filter, setFilter] = useState({ facility: [], region: [], order: 'az', searchCep: '' })

	// Caso ele pemita o GPS depois que entrou na tela, é necessário fazer um re-render pq o latlong atualiza depois
	// do primeiro render
	useEffect(() => {
		let order = 'az'
		if (typeof Storage !== 'undefined') {
			const sessionOrder = sessionStorage.getItem('unitOrder')
			order = sessionOrder || order
		}
		setPageStatus(latlong ? 'GPS_ENABLED' : 'IDLE')
		setFilter({ ...filter, order: order })
		//eslint-disable-next-line
	}, [latlong])

	const [filterMobile, setFilterMobile] = useState({ facility: '', region: '' })
	const [cep, setCep] = useState('')

	const [gmapsStatus, setGmapsStatus] = useState('')
	const [messageBelowInput, setMessageBelowInput] = useState('')

	const handleClickCheckFilter = (value, type) => () => {
		handleChangeFilter(value, type)({ target: { checked: !filter[type].includes(value) } })
	}
	const handleChangeFilter = useCallback(
		(slug, type) => ({ target }) => {
			if (slug) {
				if (target.checked) {
					setFilter({
						...filter,
						[type]: [...filter[type], slug]
					})
					setPageStatus('FILTERED')
				} else {
					setFilter({
						...filter,
						[type]: filter[type].filter(item => item !== slug)
					})
					setPageStatus(latlong ? 'GPS_ENABLED' : 'IDLE')
				}
			} else {
				if (typeof Storage !== 'undefined') {
					sessionStorage.setItem('unitOrder', target.value)
				}
				setFilter({
					...filter,
					order: target.value
				})
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[filter, latlong]
	)

	const handleSelectFilter = useCallback(
		type => select => {
			setFilterMobile({
				...filterMobile,
				[type]: select
			})
			setPageStatus('FILTERED')
		},
		[filterMobile]
	)

	const [unidades, setUnidades] = useState(units)

	const handleSearchCep = useCallback(
		e => {
			async function buscarCepEndereco() {
				if (!cep) {
					setUnidades(units)
					setPageStatus(latlong ? 'GPS_ENABLED' : 'IDLE')
					setMessageBelowInput('')
					// é preciso também limpar o campo distância das unidades caso houver
					setUnits(units.map(unit => ({ ...unit, distance: '' })))
					return
				}

				let apiKey = process.env.GATSBY_GOOGLE_MAPS
				const response = await fetch(
					`https://maps.googleapis.com/maps/api/geocode/json?address=${cep},BR&key=${apiKey}`
				)
				const data = await response.json()

				setGmapsStatus(data.status)
				if (data.status === 'ZERO_RESULTS') {
					setUnidades(units)
					setMessageBelowInput('Endereço não encontrado :(')
					return
				}

				setMessageBelowInput(`Endereço encontrado: ${data.results[0]?.formatted_address}`)

				const lat1 = data.results[0]?.geometry.location.lat
				const lon1 = data.results[0]?.geometry.location.lng

				// console.log('latitude and longitude found!', lat1, lon1)

				const unidadesFiltradas = units.map(d => ({
					...d,
					distance: calcRadius({ lat: d.latitude, lon: d.longitude, location: { latitude: lat1, longitude: lon1 } })
				}))

				setUnits(unidadesFiltradas)
				setPageStatus('FILTERED')
				setFilter({ ...filter, order: 'd' })
			}

			buscarCepEndereco()

			//Tira o foco do input pro teclado do mobile desaparecer
			e.target.blur()
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[cep, filter, latlong, units]
	)

	// useEffect para quando o units muda, muda o unidades local também
	useEffect(() => {
		setUnidades(units)
		//console.log(units)
		//console.log(facilities)
	}, [units])

	const { facility, region, order } = filter
	const { facility: facilityMobile, region: regionMobile } = filterMobile

	/**
	 * Aplicar os filtros da Sidebar
	 * Os filtro são aplicados da seguinte maneira:
	 *
	 * facilidade: é armazenado os IDs das facilidades, sendo essa procurada dentro do objeto facilidades da unidade
	 * região: é armazenado o nome da região, comparado com a região dentro da unidade. A região é uma string.
	 */

	let unitsAfterFilter = useMemo(
		() =>
			unidades
				.filter(unit => {
					const byFacility = facility.length
						? facility.find(item => unit.facilidades.find(({ slug }) => item === slug))
						: true

					const byRegion = region.length ? region.find(item => unit.regiao === item) : true
					const byMobileFacility = facilityMobile
						? unit.facilidades.find(({ slug }) => facilityMobile.value === slug)
						: true
					const byMobileRegion = regionMobile ? unit.regiao === regionMobile.value : true
					return byFacility && byRegion && byMobileFacility && byMobileRegion
				})
				.sort((a, b) => {
					if (order) {
						if (order === 'az') {
							return a.nome < b.nome ? -1 : a.nome > b.nome ? 1 : 0
						} else if (order === 'za') {
							return a.nome > b.nome ? -1 : a.nome < b.nome ? 1 : 0
						}
					}
					if (a.distance && b.distance) {
						return a.distance - b.distance
					} else {
						return 0
					}
				}),
		[unidades, facility, facilityMobile, region, order, regionMobile]
	)

	let featuredUnit = null

	if (unitsAfterFilter.length > 1 && pageStatus !== 'FILTERED') {
		if (!order || order === 'd') {
			const featured = unitsAfterFilter[0]
			if (!featuredUnit || featuredUnit.id !== featured.id) {
				featuredUnit = featured
			}
		} else {
			const featured = unitsAfterFilter.find(i => i.destaque)
			if (!featuredUnit || featuredUnit.id !== featured.id) {
				featuredUnit = featured
			}
		}
	}
	const hasRegions = useMemo(() => regions && regions.length > 0, [regions])
	const hasFacilities = useMemo(() => facilities && facilities.length > 0, [facilities])

	const noFilterMode = units.length <= 4

	return (
		<MainContainer marginless={true}>
			<BreadcrumbAuto title='Unidades' />

			<DivForSearchCepMobile>
				<Search
					id='search-cep-unit-mobile'
					idButton='button-search-cep-unit-mobile'
					label='CEP ou Endereço'
					onSearch={handleSearchCep}
					onKeyPress={handleSearchCep}
					onChange={e => setCep(e.target.value)}
					iconLeft={() => <PinIconArrow />}
					fontSize='14px'
					lineHeight='50px'
					maxLength='9'
				/>
			</DivForSearchCepMobile>
			<SpanBelowInputMobile isError={gmapsStatus !== 'OK'}>{messageBelowInput}</SpanBelowInputMobile>

			<CustomContent noFilterMode={noFilterMode}>
				<Div className='left'>
					{noFilterMode !== true && (
						<SidebarAside>
							<Search
								id='search-cep-unit'
								idButton='button-search-cep-unit'
								label='CEP ou Endereço'
								onSearch={handleSearchCep}
								onKeyPress={handleSearchCep}
								onChange={e => setCep(e.target.value)}
								iconLeft={() => <PinIconArrow />}
								fontSize='14px'
								lineHeight='50px'
								maxLength='50'
							/>
							<SpanBelowInput isError={gmapsStatus !== 'OK'}>{messageBelowInput}</SpanBelowInput>

							{(hasRegions || hasFacilities) && <hr />}

							{hasRegions && (
								<Div id='region-filter' p='0 0 20px 0'>
									<CheckboxFilterH4>Filtre por Região</CheckboxFilterH4>
									{regions.map(region => (
										<CheckboxContainer
											id={`region-filter-item-${region.value}`}
											onClick={handleClickCheckFilter(region.value, 'region')}
											key={`check-item-region-${region.slug}`}
										>
											<div>
												<CheckBox
													checked={filter['region'].includes(region.value)}
													type='checkbox'
													name={region.slug}
													onChange={handleChangeFilter(region.value, 'region')}
												/>
												<label htmlFor={region.slug}>{region.label}</label>
											</div>
											<CheckboxSpan>({region.count})</CheckboxSpan>
										</CheckboxContainer>
									))}
								</Div>
							)}

							{hasFacilities && (
								<Div id='facilities-filter' p='0 0 20px 0'>
									<CheckboxFilterH4>Filtre por Facilidades</CheckboxFilterH4>
									{facilities.map(facility => (
										<CheckboxContainer
											id={`facilities-filter-${facility.value}`}
											onClick={handleClickCheckFilter(facility.value, 'facility')}
											key={`check-item-facility-${facility.slug}`}
										>
											<div>
												<CheckBox
													type='checkbox'
													name={facility.slug}
													onChange={handleChangeFilter(facility.value, 'facility')}
												/>
												<label htmlFor={facility.slug}>{facility.label}</label>
											</div>
											<CheckboxSpan>({facility.count})</CheckboxSpan>
										</CheckboxContainer>
									))}
								</Div>
							)}
						</SidebarAside>
					)}
				</Div>
				<div className='right'>
					{unitsAfterFilter.length > 0 ? (
						<>
							{pageStatus !== 'FILTERED' && featuredUnit && (
								<>
									<MainTitle>{latlong ? 'Unidade mais próxima' : 'Unidade destaque'}</MainTitle>
									<UnidadeDestaque unit={featuredUnit} />
								</>
							)}
							<SubTitle>Todas as unidades</SubTitle>
							<Div m='30px 0' mMob='23px 0'>
								{(hasRegions || hasFacilities) && (
									<FiltrosMobileDiv>
										{hasFacilities && (
											<Select
												id='select-region-mobile'
												selected={filterMobile.region}
												onChange={handleSelectFilter('region')}
												label='Região'
												options={regions}
												labelFont={'13px !important'}
												inputBackground={'transparent'}
												selectWidth={'45%'}
												marginRight={!facilities.length && '0'}
												theme={themeObject}
											/>
										)}

										{hasFacilities && (
											<Select
												id='select-facility-mobile'
												selected={filterMobile.facility}
												onChange={handleSelectFilter('facility')}
												label='Facilidades'
												options={facilities}
												labelFont={'13px !important'}
												inputBackground={'transparent'}
												selectWidth={'45%'}
												theme={themeObject}
											/>
										)}
									</FiltrosMobileDiv>
								)}
								{noFilterMode !== true && (
									<NavbarCustom expand='lg'>
										<FlexDiv justify='space-between' align='center' p='0 20px'>
											<NavCount>{unitsAfterFilter.length} unidades:</NavCount>
											<FlexDiv justify='space-between' align='center'>
												<NavTexto>Ordenar por:</NavTexto>
												<NavSelect id='select-filter' onChange={handleChangeFilter()} value={filter.order}>
													<NavOption value='d'>Distância</NavOption>
													<NavOption value='az'>De A - Z</NavOption>
													<NavOption value='za'>De Z - A</NavOption>
												</NavSelect>
												<CustomIconArrow />
											</FlexDiv>
										</FlexDiv>
									</NavbarCustom>
								)}
							</Div>
							<CardsUnidades
								units={unitsAfterFilter}
								noFilterMode={noFilterMode}
								unidadeDestaque={featuredUnit}
								filter={filter}
								hasRegions={hasRegions}
								hasFacilities={hasFacilities}
							/>
						</>
					) : (
						<EmptyResult condition={unitsAfterFilter.length === 0} />
					)}
				</div>
			</CustomContent>
		</MainContainer>
	)
}

export default Unidades
