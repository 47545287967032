import React from 'react'
// Imports from gatsby.
import { graphql } from 'gatsby'

import { UnidadeDetalhe } from '@monorepo-amais/commons/components/unidades/'
import Layout from '../components/layout'

export default function UnidadesTemplate({ pageContext, data }) {
	const { unidades } = data // There is also 'site' object in data.

	return <UnidadeDetalhe {...unidades} Layout={Layout} />
}

export const pageQuery = graphql`
	query($slug: String!) {
		unidades(slug: { eq: $slug }) {
			id
			slug
			bairro
			cep
			cidade
			endereco
			estado
			exames
			facilidades {
				descricao
				facilidade
				icone {
					imgix_url
					url
				}
			}
			fotos {
				imagem {
					url
					imgix_url
				}
			}
			horariosAtendimento {
				domingo {
					horario_final
					horario_inicial
					observacao
					titulo
				}
				sabado {
					horario_final
					horario_inicial
					observacao
					titulo
				}
				segunda_a_sexta {
					horario_final
					horario_inicial
					observacao
					titulo
				}
			}
			id
			idLegado
			latitude
			longitude
			marca
			nome
		}
	}
`

//trecho removido da query acima por não ser mais utilizado
// 	servicosDiferenciados {
//	comoUtilizar
//	descricaoCurta
//	sobre
//	videoLink
//}
