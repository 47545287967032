const toRad = x => (x * Math.PI) / 180

export const calcRadius = ({ lat, lon, location }) => {
	const { latitude, longitude } = location

	const R = 6371 // km
	const x1 = lat - latitude
	const dLat = toRad(x1)
	const x2 = lon - longitude
	const dLon = toRad(x2)
	const a =
		Math.sin(dLat / 2) * Math.sin(dLat / 2) +
		Math.cos(toRad(latitude)) * Math.cos(toRad(lat)) * Math.sin(dLon / 2) * Math.sin(dLon / 2)
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))

	return R * c
}

export const getGeolocation = cb => {
	if (typeof navigator !== 'undefined') {
		if ('geolocation' in navigator) {
			navigator.geolocation.getCurrentPosition(
				function success(position) {
					const { latitude, longitude } = position.coords
					cb({ latitude, longitude })
				},
				function error(error_message) {
					console.error('An error has occured while retrieving location', error_message)
				}
			)
		} else {
			console.log('geolocation is not enabled on this browser')
		}
	}
}
